import { useEffect, useState } from "react";
import { isMobile, isMobileWidth } from "../../libs/userAgent";
import "./index.scss";





const Home = (): JSX.Element => {
  const [HomeHeight, setHomeHeight] = useState('100vh')
  const init = () => {
    if (isMobile()) {
      const h = document.documentElement.clientHeight
      console.log('%c🀂 h', 'color: #ff0000; font-size: 20px;', h);
      setHomeHeight(`${h}px`)
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <>
      <div className="home"
        style={{
          height: isMobile() ? HomeHeight : '100vh'
        }}
      >
        <div className="main">
          <h1 className="logo">
            <img src={require('../../assets/logo.png').default} alt="logo" />
          </h1>
          <p className="des">Web3.0 Product Design and  Development Studio</p>
          <div className="btn">
            <button onClick={() => {
              window.open('https://t.me/Terr3nce17', '_blank')
            }}>let your ideas shine</button>
          </div>
        </div>
        <div className="footer">
          <div className="cooperate_list">
            <div className="item">
              <img src={require('../../assets/cooperate1.png').default} alt="" />
            </div>
            <div className="item">
              <img src={require('../../assets/cooperate2.png').default} alt="" />
            </div>
            <div className="item">
              <img src={require('../../assets/cooperate3.png').default} alt="" />
            </div>
            <div className="item">
              <img src={require('../../assets/cooperate4.png').default} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
